<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-05-04 21:21:35
 * @LastEditors: lily
 * @LastEditTime: 2022-05-09 14:23:30
-->
<template>
  <div class="zh-config">
    <div class="zh-config-box">
      <div class="zh-config-title">园所设置</div>
      <div class="zh-config-content">
        <happy-scroll color="#a3d3e360" size="5" hide-horizontal>
          <div class="zh-config-content-box">
            <div class="school-fc">
              <div class="school-config-title">
                园所风采 [ {{ schoolView.length }}/4 ]
                <span @click="bindAdd(1)" v-if="schoolView.length < 4">
                  <img src="../../../../assets/img/zhSchool/Icon_add.png" alt="" />
                </span>
              </div>
              <div class="school-fc-content">
                <div
                  class="school-fc-content-item"
                  v-for="(item, index) in schoolView"
                  :key="index"
                >
                  <div class="handle" @click="handDelete(index, 1)">
                    <img
                      src="../../../../assets/img/zhSchool/Icon_Delet.png"
                      alt=""
                    />
                  </div>
                  <div class="uoload-img" v-if="item.url">
                    <img :src="item.url" class="avatar" />
                  </div>
                  <el-upload
                    v-else
                    class="avatar-uploader"
                    :action="constant.ZH_UPLOAD_URL + '/upload/file'"
                    :headers="{ token: $store.state.userInfo.token }"
                    :show-file-list="false"
                    accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF"
                    :on-success="
                      handleAvatarSuccess.bind(null, {
                        index: index,
                        data: item,
                        modul: '1',
                      })
                    "
                    :before-upload="beforeAvatarUpload"
                  >
                    <div class="upload-con">
                      <img
                        src="../../../../assets/img/zhSchool/Icon_Update.png"
                        alt=""
                      />
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="school-ln">
              <div class="school-config-title">教学理念</div>
              <div class="school-ln-content">
                <el-input
                  resize="none"
                  type="textarea"
                  maxlength="80"
                  show-word-limit
                  v-model="teachingConcept"
                >
                </el-input>
              </div>
            </div>
            <div class="school-ll">
              <div class="school-config-title">
                师资力量[ {{ wisdomSchoolTeacherList.length }}/4 ]
                <span
                  @click="bindAdd(2)"
                  v-if="wisdomSchoolTeacherList.length < 4"
                >
                  <img src="../../../../assets/img/zhSchool/Icon_add.png" alt="" />
                </span>
              </div>
              <div class="school-config--ll-content">
                <div
                  class="school-config--ll-content-item"
                  v-for="(item, index) in wisdomSchoolTeacherList"
                  :key="index"
                >
                  <div class="handle" @click="handDelete(index, 2)">
                    <img
                      src="../../../../assets/img/zhSchool/Icon_Delet.png"
                      alt=""
                    />
                  </div>
                  <div class="item-add-left">
                    <div class="uoload-tec-img" v-if="item.headImg">
                      <img :src="item.headImg" class="avatar" />
                    </div>
                    <el-upload
                      class="avatar-uploader"
                      :action="constant.ZH_UPLOAD_URL + '/upload/file'"
                      :headers="{ token: $store.state.userInfo.token }"
                      :show-file-list="false"
                      accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF"
                      v-else
                      :on-success="
                        handleAvatarSuccess.bind(null, {
                          index: index,
                          data: item,
                          modul: '2',
                        })
                      "
                      :before-upload="beforeAvatarUpload"
                    >
                      <div class="upload-tec-con">
                        <img
                          src="../../../../assets/img/zhSchool/Icon_Update.png"
                          alt=""
                        />
                      </div>
                    </el-upload>
                  </div>
                  <div class="item-add-right">
                    <div class="item-add-right-top">
                      <div class="item-add-right-top-name">
                        <el-input
                          v-model="item.name"
                          placeholder="姓名"
                          plac
                          :maxlength="5"
                        ></el-input>
                      </div>
                      <div class="item-add-right-top-education">
                        <el-select v-model="item.education" placeholder="学历">
                          <el-option
                            v-for="(items, index) in education"
                            :key="index"
                            :label="items.label"
                            :value="items.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="item-add-right-botton-weigh">
                        <el-select v-model="item.sex" placeholder="性别">
                          <el-option
                            v-for="(items, index) in optionSex"
                            :key="index"
                            :label="items.label"
                            :value="items.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="item-add-right-bottom">
                      <el-input
                        resize="none"
                        type="textarea"
                        maxlength="38"
                        show-word-limit
                        v-model="item.remarks"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="school-zz">
              <div class="school-config-title">
                荣誉资质[ {{ schoolHonor.length }}/4 ]
                <span @click="bindAdd(3)" v-if="schoolHonor.length < 4">
                  <img src="../../../../assets/img/zhSchool/Icon_add.png" alt="" />
                </span>
              </div>
              <div class="school-fc-content">
                <div
                  class="school-fc-content-item"
                  v-for="(item, index) in schoolHonor"
                  :key="index"
                >
                  <div class="handle" @click="handDelete(index, 3)">
                    <img
                      src="../../../../assets/img/zhSchool/Icon_Delet.png"
                      alt=""
                    />
                  </div>
                  <div class="uoload-ry-img" v-if="item.url">
                    <img :src="item.url" class="avatar" />
                  </div>
                  <el-upload
                    v-else
                    class="avatar-uploader"
                    :action="constant.ZH_UPLOAD_URL + '/upload/file'"
                    :headers="{ token: $store.state.userInfo.token }"
                    :show-file-list="false"
                    accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF"
                    :on-success="
                      handleAvatarSuccess.bind(null, {
                        index: index,
                        data: item,
                        modul: '3',
                      })
                    "
                    :before-upload="beforeAvatarUpload"
                  >
                    <div class="upload-ry-con">
                      <img
                        src="../../../../assets/img/zhSchool/Icon_Update.png"
                        alt=""
                      />
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
            <!-- 分隔 -->
            <div class="split-screen">
              <div class="school-config-title">分屏风格</div>
              <div class="split-screen-content">
                <div
                  class="screen-list"
                  v-for="(item, index) in wisdomScreenStyleId"
                  :key="index"
                >
                  <div class="screen-item" @click="bindSelectScreen(item)">
                    <div
                      :class="
                        item.id == ScreenStyleId
                          ? 'screen-item-box-active'
                          : 'screen-item-box'
                      "
                    >
                      <img :src="item.screenStyleImg" alt="" />
                     
                      <span v-if="item.id == ScreenStyleId">
                        <img
                          src="../../../../assets/img/zhSchool/base/Icon_FPFG_Choose.png"
                          alt=""
                        />
                      </span>
                    </div>
                    <p class="screen-name">{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 专项定制 -->
            <div class="special-customized">
              <div class="school-config-title">专项定制</div>
              <div class="special-customized-content">
                <h2 class="special-customized-content-z">
                  技术改变商业生态，赋能园所做强做大。园所可根据自身需求，我们提供服务：
                </h2>
                <ul class="special-customized-content-tis">
                  <li>
                    1.任意定制<span>一套宣传屏</span>，每屏提供4个功能区，可从提供的数据字段里选择开发；
                  </li>
                  <li>
                    2.互动教学为您提供摄像头插件服务，部署在线教育互动平台，提供便捷接入、高效流畅、低延迟的视音频服务；
                  </li>
                  <li>
                    3.提供AI人工智能运动插件服务，通过人脸识别、视频采集与视频主动分析，实现了运动与AI的有机整合，让幼儿线上运动变得简单又充满趣味。
                  </li>
                </ul>
                <p>专业开发 了解更多解决方案，请联系我们：010-7276389</p>
              </div>
            </div>
            <div class="config-btn" @click="getSetingData">开始使用</div>
          </div>
        </happy-scroll>
      </div>
    </div>

    <!-- <popQue  /> -->
    <popTis v-if="isPopCon" :title="popTitle" @isCode="isCode"></popTis>

  </div>
</template>
<script>
import { HappyScroll } from "vue-happy-scroll";
import "vue-happy-scroll/docs/happy-scroll.css";

import popQue from "../../components/popup/popQue.vue";
 import popTis from "../../components/popup/popTis.vue";
export default {
  components: {
    popQue,
    popTis,
    HappyScroll,
  },
  data() {
    return {
      isPoptis:false,
      popTitle: "", // 弹出框文本
      isPopCon: false, // 弹出框判断条件
      optionSex: [
        {
          label: "男",
          value: "1",
        },
        {
          label: "女",
          value: "2",
        },
      ],
      education: [
        {
          label: "博士",
          value: "0",
        },
        {
          label: "硕士",
          value: "1",
        },
        {
          label: "本科",
          value: "2",
        },
        {
          label: "专科",
          value: "3",
        },
        {
          label: "高中",
          value: "4",
        },
        {
          label: "初中",
          value: "5",
        },
      ],
      sereen: [
        {
          index: "1",
          imgUrl: "",
          text: "风格一",
        },
        {
          index: "2",
          imgUrl: "",
          text: "风格二",
        },
        {
          index: "3",
          imgUrl: "",
          text: "风格三",
        },
        {
          index: "4",
          imgUrl: "",
          text: "风格四",
        },
        {
          index: "5",
          imgUrl: "",
          text: "风格五",
        },
        {
          index: "6",
          imgUrl: "",
          text: "风格六",
        },
      ],
      schoolViewImg: [], // 存放图片
      schoolView: [
        {
          url: "",
        },
      ], // 园所风采
      teachingConcept: "",
      schoolHonorImg: [], // 存放图片
      schoolHonor: [
        {
          url: "",
        },
      ],
      //ScreenStyleId: "1", // 样式选择的ID
      wisdomScreenStyleId: [],
      // 教师头像图片存放
      headImgList: [],
      wisdomSchoolTeacherList: [
        // 教学力量
        {
          name: "",
          sex: "",
          education: "",
          remarks: "",
          headImg: "",
        },
      ],
      itemNaNpxarks:"",
      ScreenStyleId:1
    };
  },
  mounted() {
    // 记载默认主题颜色
    //window.document.documentElement.setAttribute("data-theme", "base");
     //window.document.documentElement.setAttribute("data-theme", "Blue");
    this.init();
  },
  methods: {
    // 默认执行
    init() {
      this.getScreenList();
      this.getSmartSchoolInfo();
    },
    // 获取园所设置默认
    getSmartSchoolInfo() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          //this.schoolData = res.data;

          this.schoolHonor = res.data.schoolHonor;
          this.schoolView = res.data.schoolView;
          this.wisdomSchoolTeacherList = res.data.wisdomSchoolTeachers;
          this.teachingConcept = res.data.teachingConcept;
          this.ScreenStyleId = res.data.wisdomScreenStyleId?res.data.wisdomScreenStyleId:1;
        });
    },
    /**
     * 生产空白数据
     * @param {String} type 类型：credit园所风采、faculty师资力量、annex荣誉资质
     * @returns {Object}
     */
    newEmpty(type = "") {
      const factory = {
        // 生产一个空的园所风采
        credit() {
          return {
            url: "",
          };
        },

        // 生产一个空的师资力量
        faculty() {
          return {
            name: "",
            sex: "",
            education: "",
            remarks: "",
            headImg: "",
          };
        },

        // 生产一个空的荣誉资质
        annex() {
          return {
            url: "",
          };
        },
      };

      if (!Object.keys(factory).includes(type)) {
        return {};
      }

      return factory[type]();
    },
    /**
     * 检查现有的列表
     * @param {String} type 类型：credit园所风采、faculty师资力量、annex荣誉资质
     * @param {Array} exists 现有的列表
     * @returns {boolean} 没问题返回true，有问题返回false
     */
    check(type = "", exists = []) {
      const empty = this.newEmpty(type); // 先获取一个空的数据
      if (exists.length <= 0) {
        return false;
      }
      for (let i = 0; i < exists.length; i++) {
        for (let k in exists[i]) {
          if (exists[i][k] === empty[k]) {
            return false;
          }
        }
      }
      return true;
    },
    checkCredit() {
      if (!this.check("credit", this.schoolView)) {

        this.isPopCon = true;

        this.popTitle = "您还有没填写的园所风采";
        return false;
      }
      return true;
    },
    // 检查师资力量是否有空着没填的
    checkFaculty() {
      if (!this.check("faculty", this.wisdomSchoolTeacherList)) {

        this.isPopCon = true;

        this.popTitle = "您还有没填写的师资力量";
        return false;
      }
      return true;
    },

    checkAnnex() {
      if (!this.check("annex", this.schoolHonor)) {

        this.isPopCon = true;

        this.popTitle = "您还有没填写的荣誉资质";
        return false;
      }
      return true;
    },
    // 提交示范园信息
    getSetingData() {
      if (!this.checkCredit() || !this.checkFaculty() || !this.checkAnnex()) {
        return;
      }
      if (this.teachingConcept == "") {

        this.isPopCon = true;

        this.popTitle = "教学理念不能为空";
        return;
      }
      if (this.ScreenStyleId == "") {

        this.isPopCon = true;

        this.popTitle = "请选择分屏风格";
        return;
      }
      this.schoolView = this.schoolView.map((item) => {
        return item.url;
      });
      this.schoolHonor = this.schoolHonor.map((item) => {
        return item.url;
      });
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        schoolView: this.schoolView.join(","),
        teachingConcept: this.teachingConcept,
        schoolHonor: this.schoolHonor.join(","),
        wisdomScreenStyleId: this.ScreenStyleId,
        wisdomSchoolTeacherList: this.wisdomSchoolTeacherList,
      };
      this.api.zhSchool.schoolModify(data).then((res) => {
        this.getSmartSchoolInfo();
        if (res.errCode == "-1") {

          this.isPopCon = true;

          this.popTitle = res.errMessage;

          

        } else {
          console.log("333")
          this.$emit("skinpeeler")
        }
      });
    },
    // 修改园所设置
    updateSchoolList() {
      let data = {
        wisdomSchoolId: this.$store.state.userInfo.schoolId,
        schoolView: this.schoolViewImg.join(","),
        teachingConcept: this.teachingConcept,
        schoolHonor: this.schoolHonorImg.join(","),
        wisdomScreenStyleId: this.ScreenStyleId,
        wisdomSchoolTeacherList: this.wisdomSchoolTeacherList,
      };
      this.api.zhSchool.schoolModify(data).then((res) => {
        console.log(res, "提交示范园信息");
        // if (res.flag) {
        //   this.$emit("bindIsSet", "11111111");
        // }
      });
    },
    // 风格选择
    bindSelectScreen(data) {
      this.ScreenStyleId = data.id;
    },
    // isCode 弹出框关闭
    isCode() {
      this.isPopCon = false;
      this.isPoptis = false;
    },
    // 提交设置页面
    bindAdd(types) {
      if (types == 1) {
        this.schoolView.push({
          url: "",
        });
      } else if (types == 2) {
        this.wisdomSchoolTeacherList.push({
          name: "",
          sex: "",
          education: "",
          remarks: "",
          headImg: "",
        });
      } else if (types == 3) {
        this.schoolHonor.push({
          url: "",
        });
      }
    },
    handDelete(index, types) {
      if (types == 1) {
        this.schoolView.splice(index, 1);
      } else if (types == 2) {
        this.wisdomSchoolTeacherList.splice(index, 1);
      } else if (types == 3) {
        this.schoolHonor.splice(index, 1);
      }
    },
    handleAvatarSuccess(obj, res) {
      if (obj.modul == 1) {
        this.schoolView[obj.index].url = res.data.url;
      } else if (obj.modul == 3) {
        this.schoolHonor[obj.index].url = res.data.url;
      } else if (obj.modul == 2) {
        this.wisdomSchoolTeacherList[obj.index].headImg = res.data.url;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/JPG" ||
        file.type === "image/JPEG" ||
        file.type === "image/GIF";
      console.log(file.type, "查看什么类型");
      if (!isJPG) {
        this.isPopCon = true;
        this.popTitle = "上传只支持jpeg/jpg/png/gif/JPG/JPEG/GIF图片格式";
      }
      return isJPG;
    },

    // 屏幕样式列表
    getScreenList() {
      this.api.zhSchool.screenList().then((res) => {
        if (res.errCode) {
          this.wisdomScreenStyleId = res.data.data;
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/setting/_handle.scss";
.zh-config {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .zh-config-box {
    width: 1242px;
    height: 892px;
    @include background_bg("bgImage");
    background-size: 100% 100%;
    border-radius: 5px;
    .zh-config-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      line-height: 40px;
    }
    .zh-config-content {
      width: 1130px;
      height: 779px;
      padding-left: 60px;
      .zh-config-content-box {
        width: 11.2rem;
      }
      .school-fc-content {
        display: flex;
        flex-wrap: wrap;
        .school-fc-content-item:last-child {
          margin-right: 0;
        }
        .school-fc-content-item {
          width: 255px;
          position: relative;
          margin-right: 30px;
          .handle {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: -14px;
            img {
              width: 32px;
              height: 32px;
            }
          }
          .uoload-img {
            width: 259px;
            height: 144px;
            background: url(../../../../assets/img/zhSchool/Pic_Fc_Zs.png)
              no-repeat #0e2536;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
            img {
              width: 255px;
              height: 140px;
              border-radius: 6px;
            }
          }
          .uoload-ry-img {
            width: 259px;
            height: 137px;
            background: url(../../../../assets/img/zhSchool/Pic_RY_Zs.png)
              no-repeat #0e2536;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
            img {
              width: 255px;
              height: 133px;
              border-radius: 6px;
            }
          }
          .upload-ry-con {
            width: 259px;
            height: 144px;
            @include background_bg("yyzzbgImage");
            @include background_color("bgColor");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
          }
          .upload-con {
            width: 259px;
            height: 144px;
            @include background_bg("ysfcbgImage");
            @include background_color("bgColor");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
          }

          .uoload-js-img {
            width: 155px;
            height: 92px;
            background: url(../../../../assets/img/zhSchool/Pic_SFYDT_Zs.png)
              no-repeat #0e2536;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
            img {
              width: 155px;
              height: 92px;
            }
          }
          .upload-js-con {
            width: 155px;
            height: 92px;
            background: url(../../../../assets/img/zhSchool/Pic_SFYDT_Zs.png)
              no-repeat #0e2536;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
          }
        }
      }
      .school-ln {
        margin-top: 14px;
        /deep/ .el-textarea {
          width: 1120px;
          height: 85px;
        }
        .school-ln-content {
          /deep/ .el-textarea__inner {
            width: 1120px;
            height: 85px;
            @include background_color("txtBgColor");
            @include border_color("borderColor");
            border-radius: 4px;
            font-size: 16px;
            @include font_color("txtColor");
          }
          /deep/ .el-input__count {
            background: none;
            @include font_color("txtLenghColor");
          }
        }
      }
      .school-sry,
      .school-ll {
        margin-top: 14px;
        .school-config--ll-content {
          display: flex;
          flex-wrap: wrap;
          .school-config--ll-content-item {
            position: relative;
            width: 502px;
            height: 160px;
            @include background_bg("ysllbgImage");
            background-size: 100% 100%;
            padding: 14px 25px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            margin-right: 24px;
            margin-bottom: 20px;
          }
          .handle {
            cursor: pointer;
            position: absolute;
            right: -10px;
            top: -14px;
            img {
              width: 32px;
              height: 32px;
            }
          }
          .upload-tec-con {
            width: 102px;
            height: 132px;
            @include background_color("ysllBgColor");
            box-shadow: 0px 3px 6px 0px rgba(74, 103, 130, 0.33);
            opacity: 0.4;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 28px;
              height: 28px;
            }
          }
          .uoload-tec-img {
            img {
              width: 102px;
              height: 132px;
            }
          }
          .item-add-right {
            .item-add-right-top {
              display: flex;
              width: 326px;
              justify-content: space-between;
              padding-bottom: 15px;
              /deep/ .item-add-right-top-name .el-input__inner {
                width: 82px;
                height: 30px;
                background: rgba(9, 25, 36, 0.2);
                @include border_color("borderColor");
                border-radius: 4px;
                font-size: 14px;
                color: #c3e8ff;
              }
              /deep/ .item-add-right-top-education .el-input__inner {
                width: 82px;
                height: 30px;
                background: rgba(9, 25, 36, 0.2);
                @include border_color("borderColor");
                border-radius: 4px;
                font-size: 14px;
                color: #c3e8ff;
              }
              /deep/ .item-add-right-botton-weigh .el-input__inner {
                width: 122px;
                height: 30px;
                background: rgba(9, 25, 36, 0.2);
                @include border_color("borderColor");
                border-radius: 4px;
                font-size: 14px;
                color: #c3e8ff;
              }
            }
            /deep/ .item-add-right-bottom .el-textarea__inner {
              width: 326px;
              height: 87px;
              background: rgba(9, 25, 36, 0.2);
              @include border_color("borderColor");
              border-radius: 4px;
              @include font_color("txtColor");
            }
            /deep/ .el-textarea .el-input__count {
              background: none;
              @include font_color("txtLenghColor");
            }
            /deep/ .el-input__icon {
              line-height: 30px;
            }
            /deep/ .el-icon-arrow-up:before {
              color: #c3e8ff;
            }
          }
        }
      }
    }
  }
  .split-screen {
    margin-top: 14px;
    .split-screen-content {
      display: flex;
      flex-wrap: wrap;
    }
    .screen-list {
      margin-right: 10px;
      .screen-item {
        text-align: center;
      }
      .screen-item-box {
        @include background_bg("fpfgbgImage");
        background-size: 100% 100%;
        width: 269px;
        height: 162px;
        img{
          width:100%;
          height:100%;
        }
      }
      .screen-item-box-active {
        @include background_bg("fpfgSelectbgImage");
        background-size: 100% 100%;
        width: 269px;
        height: 162px;
        position: relative;
        span {
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
        img{
          width:100%;
          height:100%;
        }
      }
      .screen-name {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
      }
    }
  }
  .special-customized {
    .special-customized-content {
      .special-customized-content-z {
        @include font_color("titleColor");
        font-size: 18px;
      }
      .special-customized-content-tis {
        padding: 16px 0 20px 0;
        font-size: 16px;
        @include font_color("tisColor");
        li {
          @include font_color("tisColor");
          opacity: 0.8;
          margin-bottom: 10px;
          span {
            @include font_color("tisAcitveColor");
          }
        }
      }
      p {
        @include font_color("titleColor");
        font-size: 16px;
      }
    }
  }
  .school-config-title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    @include font_color("mainColor");
    line-height: 30px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    img {
      width: 32px;
      height: 32px;
      margin-left: 6px;
    }
  }
}
.config-btn {
  width: 134px;
  height: 46px;
  @include background_bg("btnbgImage");
  background-size: 100% 100%;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  @include font_color("mainColor");
  line-height: 30px;
  text-align: center;
  line-height: 46px;
  margin-bottom: 50px;
  cursor: pointer;
  margin: 40px auto;
}
/deep/.el-input__inner {
  &::placeholder {
    color: #6b8e99;
  }

  &::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    color: #6b8e99;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 适配火狐 */
    color: #6b8e99;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ 适配火狐 */
    color: #6b8e99;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+  适配ie*/
    color: #6b8e99;
  }
}
</style>