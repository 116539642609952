<template>
  <div class="pop-que">
    <div class="pop-content">
      <div class="pop-title1">您还未开通该项权限</div>
      <img src="../../../../assets/img/zhSchool/Pic_Bg_Tc_close.png" alt="" class="Pic_Bg_Tc_close" @click="bindSet">
      <div class="pop-img">
        <img src="../../../../assets/img/zhSchool/Pic_Tc_WKTQX.png" alt="" />
        <p class="ts-title">购买套餐使用平台服务，立享专属特权！</p>
      </div>
      <div class="pop-title2">
        <img src="../../../../assets/img/zhSchool/Pic_Tc_TCQX.png" alt="" />
      </div>
      <div class="pop-btn" @click="bindRetrun">立即购买</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    bindRetrun() {
      this.$router.push({
        path: "/product",
        query: {
          type: "zh",
        },
      });
    },
    bindSet(){
      this.$emit('bindSet')
    }
  },
};
</script>
<style lang="scss" scoped>
.pop-que {
  width: 100%;
  height: 100%;
  background: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  .pop-content {
    width: 611px;
    height: 642px;
    background: url(../../../../assets/img/zhSchool/Pic_Bg_Tc_WQX.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .pop-title1 {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffc66c;
      text-align: center;
      margin-top: 100px;
    }
    .pop-title2 {
      text-align: center;
      margin-top: 58px;
      img {
        width: 513px;
        height: 151px;
      }
    }
    .pop-img {
      margin-top: 30px;
      text-align: center;
      img {
        width: 151px;
        height: 119px;
        text-align: center;
      }
      .ts-title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #b8edff;
        padding-top: 12px;
      }
    }
  }
  .ts-content {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c3e8ff;
    line-height: 30px;
    text-indent: 36px;
  }
  .pop-btn {
    width: 149px;
    height: 49px;
    background: url(../../../../assets/img/zhSchool/Bg_Btn.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 49px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c3e8ff;
    margin: 20px auto;
    cursor: pointer;
  }
  .pop-btn:hover {
    background: url(../../../../assets/img/zhSchool/Bg_Btn_H.png) no-repeat;
    background-size: 100% 100%;
  }
}
.Pic_Bg_Tc_close{
  position: absolute;
  right:2%;
  top:5%;
  width:50px;
  height:50px;
  cursor: pointer;
}
</style>
