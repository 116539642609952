<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-04-14 16:05:53
 * @LastEditors: lily
 * @LastEditTime: 2022-04-27 17:25:23
 * @LastEditTime: 2022-04-14 16:05:53
-->
<template>
  <div class="pop-que">
    <div class="pop-content">
      <div class="pop-title">温馨提示</div>
      <div class="pop-img">
        <img src="../../../../assets/img/zhSchool/Icon_Tc_Ts.png" alt="" />
        <p class="ts-title">{{ title }}</p>
      </div>
      <!-- <p class="ts-content">
        请您
      </p> -->
      <div class="pop-btn" @click="bindRetrun">确定</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    bindRetrun() {
      this.$emit("isCode");
    },
  },
};
</script>
<style lang="scss" scoped>
.pop-que {
 
 

  .pop-content {
    width: 602px;
    height: 450px;
    background: url(../../../../assets/img/zhSchool/Bg_Tc_TS.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    
    .pop-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      line-height: 40px;
    }
    .pop-img {
      padding: 0 40px;
      text-align: center;
      img {
        width: 140px;
        height: 140px;
        margin-top: 15px;
        text-align: center;
      }
      .ts-title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        padding-bottom: 30px;
        padding-top: 8px;
      }
    }
  }
  .ts-content {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c3e8ff;
    line-height: 30px;
    text-indent: 36px;
    padding: 0 40px;
  }
  .pop-btn {
    width: 149px;
    height: 49px;
    background: url(../../../../assets/img/zhSchool/Bg_Btn.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 49px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c3e8ff;
    margin: 36px auto;
    cursor: pointer;
  }
  .pop-btn:hover {
    background: url(../../../../assets/img/zhSchool/Bg_Btn_H.png) no-repeat;
    background-size: 100% 100%;
  }
}
</style>
