<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-05-03 14:11:14
 * @LastEditors: lily
 * @LastEditTime: 2022-05-09 14:16:02
-->
<template>
  <div class="zh-config">
    <component :is="currentRole" @skinpeeler="skinpeeler"/>
  
  </div>
</template>
<script>
// 海洋模式
import oceanconfig from "../html/ocean/oceanconfig.vue";
// 星空模式
import starryconfig from "../html/starry/starryconfig.vue";
export default {
  components: {
   starryconfig,
   oceanconfig
  },
  data() {
    return {
      currentRole:""
      
    };
  },
  mounted() {
    // 记载默认主题颜色
    //window.document.documentElement.setAttribute("data-theme", "base");
     //window.document.documentElement.setAttribute("data-theme", "Blue");
    this.getStyle();
  },
  methods:{
    skinpeeler(){
       this.$emit("skinpeeler")
    },
    getStyle() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.errCode == 0) {
            if (res.data.wisdomScreenStyleId == 1) {
              this.currentRole = "starryconfig";
            } else if (res.data.wisdomScreenStyleId == 2) {
              this.currentRole = "oceanconfig";
            }else{
              this.currentRole = "starryconfig";
            }
          }
          console.log(res, "1111111111111");
        });
    },
  }
  
};
</script>
<style lang="scss">
.zh-config{
  width:100%;
}
  
</style>

